import * as React from "react"
import Menu from '../../components/menu'
import Footer from '../../components/footer'
import styled from 'styled-components'
import StyledLink from '../../components/styledLink'
import '../../styles/typography.css'
import SubPageHero from "../../components/sub-page-hero"
import squiggles from '../../images/squiggles.svg'
import clock from '../../images/clock-icon.svg'
import euro  from '../../images/euro-icon.svg'
import HelmetInfo from '../../components/helmet-info'
import 'animate.css'

const SquigglesImage = () => {
    return <img className="hero-squiggles" src={squiggles} alt="decorative squiggles"/>
}

const ServiceWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;

    h2, p {
        text-align: left;
    }
    .inlineLink { 
        margin-left: 20px;
        margin-top: 10px;
    }
  
    @media screen and (min-width: 900px) {
        grid-template-columns: 1fr 1fr;
    }
`

const ServiceList = () => {
    return(
        <ServiceWrapper>
            
 
            <div className="service">
                <h2>What are commercials and why use them?</h2>
                <p>Commercials featuring moving images and animation have been a staple of every good marketing executive since the inception of the moving image. They serve to promote businesses and products in one of the most widely used formats available today.</p>
                <p>Commericals should be short (between 30 and 60 seconds). They serve to gain the attention of the audience or enamor them to a new brand or idea.</p> 
            </div>
            <div className="service">
                <h2>When and how should I use an commercial video?</h2>
                <p>You've come across literally millions of commercials in your time and no doubt have felt their effects. You can use a commercial to help a struggling business or to improve sales and customer retention. They are also invaluable to strenghten customer loyalty and brand awareness.</p>
                <StyledLink className='inlineLink' to={'../../contact-us'}>Get in touch</StyledLink>
                <SquigglesImage/>

        </div>
    </ServiceWrapper>
    )
}

const ServicePriceListWrapper = styled.div`
    background: #e6e6e6;
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 20px;
`

const ServicePriceListGrid = styled.div`
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 20px;

    @media screen and (min-width: 900px) {
        grid-template-columns: 1fr 1fr;
    }

    .prices-icon {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        @media screen and (min-width: 900px) {
            grid-template-columns: 146px 1fr;
        }

        img {
            max-width: 146px;
            margin: 0 20px 20px 20px;

            @media screen and (min-width: 900px) {
                margin: 0;
            }
         
        }
        
        p { 
            text-align: left; 
        }

        h2 {
            text-align: left; 
            font-size: 2rem;
        }
    }
`

const ServicePriceListItem = ({image, title, text}) => {
  return(
    <div className="prices-icon">
        <img src={image} alt=""/>
        <div className="prices-info">
            <h2>{title}</h2> 
            <p>{text}</p>
        </div>
    </div>
  )   
}

const ServicePriceList = () => {
    return (
    <>

        <ServicePriceListWrapper>
            <ServicePriceListGrid>

                <ServicePriceListItem 
                    image={clock} 
                    title={'How long does a commercial video take to produce?'}
                    text={'Commercial videos normally take between 6-8 weeks to produce. If clients have extensive tweaks or changes it can take up to 12 weeks.'}
                />

                <ServicePriceListItem 
                    image={euro} 
                    title={'How much does a commercial video cost?'}
                    text={'Commercial videos generally cost between €5,000 and €20,000 or more. Though the budget can vary depending on the project.'}
                />

            </ServicePriceListGrid>
        </ServicePriceListWrapper>

        <FullWidth>
            <StyledLink to="../">Our Services</StyledLink>  
        </FullWidth>
    </>

        
    )
}

const FullWidth = styled.div`
    background-color: black;
    padding: 50px;
    padding-top: 10px;
`

const Shorts = () => {
    return(
        <>
        <HelmetInfo />
        <Menu/>
        <SubPageHero>
            <h1 className="animate__animated animate__bounceInDown">Animated Shorts</h1>
            <img className="hero-squiggles" src={squiggles} alt="decorative squiggles" />
        </SubPageHero>
        <ServiceList />
        <ServicePriceList />

        <Footer />
        </>
    )
}

export default Shorts